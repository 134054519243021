import {
    doc,
    setDoc,
    arrayUnion,
    getDoc,
} from 'firebase/firestore'
import { db } from '../db/firebase'

export async function addQuestion(postid, slideid, userid, text){

    const docRef = doc(db, 'posts', postid, 'slides', slideid, 'questions', userid)
    await setDoc(docRef, {
        questions: arrayUnion(
            ...[
                {
                    created_at: new Date(),
                    text: text
                }
            ]
        ) 
    }, {merge: true})
}

export async function getMyQuestions(postid, slideid, userid){
    const docRef = doc(db, 'posts', postid, 'slides', slideid, 'questions', userid)
    const querySnapshot = await getDoc(docRef)
    if (querySnapshot.exists()){       
        return querySnapshot.data()
    } else {
        return undefined
    }
}
