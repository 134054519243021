import { getBackgroundReference, getDownloadPictureURL } from "../services/posts"

export class Post {
    constructor(title, backgroundfilename, id, created_by, active){
        this.title = title
        this.backgroundfilename = backgroundfilename
        this.id = id
        this.created_by =created_by
        this.urlPicture = ''
        this.active = active
    }

    async getUrlBackground(location){
        const ImageURL = await getDownloadPictureURL(location);
        this.urlPicture = ImageURL
    }

    async getBackgroundRef(location){
        return await getBackgroundReference(location)
    }
}
