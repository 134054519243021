import '../styles/loadingdiv.css'
export default function LoadingDiv(){
    return (
        <div className="loader"></div>
    )
}

export function LoadingDivModal({withNavbar, white}){
    if (!white){
        return(
            <div className='loader-div-modal' style={withNavbar && {top: 0}}><div className='loader'></div></div>
        )
    } else {
        return(
            <div className='loader-div-modal-white' style={withNavbar && {top: 0}}><div className='loader-white'></div></div>
        )
    }
}