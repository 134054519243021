import { useNavigate } from 'react-router-dom'
import '../styles/notFoundPage.css'
import {ReactComponent as InvalidRouteIcon} from '../icons/invalideroute.svg'


export default function NotFoundPage(){
    const navigate = useNavigate()
    return(
        <div id='invalidroute-c'>
            <InvalidRouteIcon id='invalidroute-icon'/>
            <p>Seite nicht gefunden</p>
            <button onClick={()=>{navigate('/')}}>Zurück zur Startseite</button>
        </div>

        )
}