import { useState } from "react"
import { toast, ToastContainer } from "react-toastify"
import { resetPassword } from "../services/firebaseAuth"
import { useNavigate } from "react-router-dom"
import { Button } from "antd"

export default function ForgotPassword(){

    const [email, setEmail] = useState("")
    const [loading, setLoading] = useState(false)
    const [showSuccess, setShowSuccess] = useState(false)
    const navigate = useNavigate();


    const handleForgotPassword = async()=>{
        setLoading(true)
        try {
            await resetPassword(email)
            setShowSuccess(true)
            
        } catch (error) {
            toast.error('Ein Konto mit dieser E-Mail existiert nicht')
            console.log(error)
        } finally {
            setLoading(false)
        }
    }
    

    return (
        <div id="structure-login" style={{backgroundImage: `url('/wallpaper.jpg')`}}>
            <div id='logo-scrollb-login'>
                <img src="/scrollblogo.png" alt="scrollb-logo" />
            </div>
            
            <div id='container-login'>
                <h3>Kennwort zurücksetzen</h3>

                {!showSuccess ? 
                    <div id='container-login-userpassword'>
                        <label htmlFor="email-login">Deine E-mail</label>
                        <input type="email" id="email-login" value={email}
                                placeholder='email@irgendwas.com' onChange={(e)=>{setEmail(e.target.value)}}/>
                        <Button type="primary" loading={loading} onClick={handleForgotPassword}>E-mail senden</Button>
                    </div>
                : 
                    <div id='container-login-userpassword'>
                        <p style={{maxWidth: '250px'}}>E-Mail erfolgreich gesendet an: {email}</p>
                        <p style={{maxWidth: '250px', margin: '10px 0 20px 0'}}>Wenn Sie es nicht finden können, überprüfen Sie Ihr Spam-Postfach</p>
                        {/* <button className='btn-signin' onClick={()=>{navigate('/login')}}>zur Anmeldeseite</button> */}
                        <Button type="primary" onClick={()=>{navigate('/login')}}>zur Anmeldeseite</Button>
                    </div>
                }
            </div>

            {/* {isLoading && <LoadingDivModal withNavbar={true} white={true}/>} */}
            <ToastContainer/> 
            
        </div>
    )
}