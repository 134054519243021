import React, { useEffect, useRef, useState } from "react"
import '../styles/actionPanel.css'

import {ReactComponent as LikeIcon} from '../icons/like.svg'
import {ReactComponent as CommentIcon} from '../icons/comment.svg'
import {ReactComponent as ViewIcon} from '../icons/view.svg'
import {ReactComponent as SendIcon} from '../icons/send.svg'
import {ReactComponent as SwipeUpIcon} from '../icons/swipeup.svg'
import {ReactComponent as MsgSuccessIcon} from '../icons/done.svg'
import {ReactComponent as MsgSendingIcon} from '../icons/msgloading.svg'
import {ReactComponent as MsgErrorIcon} from '../icons/msgerror.svg'
import { toast } from 'react-toastify';
import { motion, AnimatePresence } from "framer-motion"

import { addQuestion, getMyQuestions } from '../services/questions'
import { addLike, removeLike, isLiked } from '../services/likes'
import { addView, removeView, isViewed } from '../services/view'


export default function ActionPanel(props){
    const refLike = useRef(null)
    const refView = useRef(null)

    const postid = props.postid
    const slideid = props.slideid
    const [like, setLike] = useState(false)
    const [view, setView] = useState(false)
    const [question, setQuestion] = useState("")
    const [myQuestions, setMyQuestions] = useState([])
    const [commentBox, setCommentBox] = useState(false)

    //........... get Actions states
    useEffect(()=>{

        (async()=>{
            
            // ...... like
            const likeState = await isLiked(postid, slideid, props.user.uid)
            if (likeState){handleLike(refLike, true)}
            
            //  ...... viewed
            const viewState = await isViewed(postid, slideid, props.user.uid)
            if (viewState){handleView(refView, true)}

            // ....... questions 
            let questionsData = await getMyQuestions(postid, slideid, props.user.uid)
            if (questionsData){
                handleAddQuestionsVisually(questionsData.questions)
            }

        })()

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    //  ......... Like | dislike
    const handleLike = async(ref, onlyVisually)=>{
        
        // OnLoad Slide Like State, without func addLike
        if (onlyVisually){
            setLike(true)
            ref.current.classList.toggle('active')
            props.getNextSlide(slideid)
            return
        }

        // Change before wait result 
        const oldStateLike = like
        setLike(prev => !prev)
        ref.current.classList.toggle('active')

        // Add or remove from Firebase
        try {
            if (!oldStateLike){
                await addLike(postid, slideid, props.user.uid)
                props.getNextSlide(slideid)
            } else {
                await removeLike(postid, slideid, props.user.uid)
            }
        } catch(error){
            
            //toggle like again
            setLike(prev => !prev)
            ref.current.classList.toggle('active')
            
            if (error.code === 'permission-denied'){
                toast.error("Beitrag wurde deaktiviert")
            } else {
                toast.error("Like error")
            }
        }
    }
    
    //  ......... Show or not Comment Box
    const handleQuestion = (e)=>{
        setCommentBox(prev => !prev)
        e.classList.toggle('active');
    }    

    //  ......... Submit question
    const handleSubmitQuestion = async(e)=>{
        if (e.key === 'Enter' || e === 'btn_click'){
            const txtQuestion = question ? question.trim() : false
            if (txtQuestion){

                const newQuestionIndex = myQuestions.length

                setMyQuestions([...myQuestions, 
                    {
                        created_at: new Date(),
                        text: txtQuestion,
                        status: 'sending'
                    }
                ])
                
                try{
                    await addQuestion(postid, slideid, props.user.uid, txtQuestion)

                    // success!
                    props.getNextSlide()
                    const updatedQuestion = {...myQuestions[newQuestionIndex], status: 'success', text: txtQuestion}
                    const newMyQuestions = [...myQuestions]
                    newMyQuestions[newQuestionIndex] = updatedQuestion
                    setMyQuestions(newMyQuestions)
                    
                } catch (error){

                    if (error.code === 'permission-denied'){
                        toast.error("Beitrag wurde deaktiviert")
                    } else {
                        toast.error("Question error")
                    }

                    // update symbol to failed
                    const updatedQuestion = {...myQuestions[newQuestionIndex], status: 'error', text: txtQuestion}
                    const newMyQuestions = [...myQuestions]
                    newMyQuestions[newQuestionIndex] = updatedQuestion
                    setMyQuestions(newMyQuestions)
        
                } finally {
                    setQuestion('')
                }
            }   
        }
    }

    // .......... Add old questions on load, load next slide if exists
    const handleAddQuestionsVisually = async(oldQuestions)=>{
        setMyQuestions(...myQuestions, oldQuestions)
        props.getNextSlide(slideid)
    }

    //  ......... Submit view
    const handleView = async(ref, onlyVisually)=>{

        // Set Viewed, without func addView
        if (onlyVisually){
            setView(true)
            ref.current.classList.toggle('active')
            props.getNextSlide(slideid)
            return
        }
        // Try without wait
        const oldStateView = view
        setView(prev => !prev)
        ref.current.classList.toggle('active')

        // Add or remove
        try {
            if (!oldStateView){
                await addView(postid, slideid, props.user.uid)
                props.getNextSlide()
            } else {
                await removeView(postid, slideid, props.user.uid)
            }
        } catch(error){
            
            //toggle view again
            setView(prev => !prev)
            ref.current.classList.toggle('active')

            if (error.code === 'permission-denied'){
                toast.error("Beitrag wurde deaktiviert")
            } else {
                toast.error("Viewed error")
            }
        }
    }
    
    return(
        <>  
            <SwipeUp isVisible={props.swipeUp}/>
            
            <div className="action-panel">
                <ViewIcon className="button-view" onClick={()=>{handleView(refView)}} ref={refView} role="view"/>
                <LikeIcon className="button-like" onClick={()=>{handleLike(refLike)}} ref={refLike} role="like"/>
                <CommentIcon className="button-comment" onClick={(e)=>{handleQuestion(e.currentTarget)}} role="open-commentbox"/>
            </div>
            
            {commentBox && 
                <QuestionInput 
                    value={question} 
                    questionsList={myQuestions}
                    changeEvent={(e)=>{setQuestion(e.currentTarget.value)}} 
                    handleSubmitQuestion={(e)=>{handleSubmitQuestion(e)}}/>}
        </>
    )
}

const QuestionInput = (props)=>{

    return (
        <div className="comment-container">
            <div className="my-comments">
                {props.questionsList.map((q, index)=>{
                    return (
                        <div key={index} className="my-comments-item">
                            <p>{q.text}</p>
                            {q.status === 'sending' && <MsgSendingIcon className="msg-status-icon"/>}
                            {q.status === 'error' && <MsgErrorIcon className="msg-status-icon"/>}
                            {(!q.status || q.status === 'success') && <MsgSuccessIcon className="msg-status-icon"/>}
                        </div>
                    )
                })}
            </div>

            <div className="comment-input-container">
                <input autoFocus className="input-question" type="text" placeholder="Etwas fragen..." value={props.value} onChange={(e)=>{props.changeEvent(e)}} onKeyUp={(e)=>{props.handleSubmitQuestion(e)}} />
                <SendIcon className="button-send" onClick={()=>{props.handleSubmitQuestion('btn_click')}} role="send-question"/>
            </div>
        </div>
    )
}

const SwipeUp = ({isVisible})=>{
    
    return(
        <AnimatePresence>
        {isVisible && (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            <div className="swipeup">
                <SwipeUpIcon className="button-swipeup"/>
            </div>
        </motion.div>
        )}
    </AnimatePresence>    
    )
}
