import {
    deleteDoc,
    doc,
    setDoc,
    getDoc,
} from 'firebase/firestore'
import { db } from '../db/firebase'

export async function addView(postid, slideid, userid){

    const docRef = doc(db, 'posts', postid, 'slides', slideid, 'view', userid)
    await setDoc(docRef, {
        exists: true
    })
}

export async function removeView(postid, slideid, userid){
    const docRef = doc(db, 'posts', postid, 'slides', slideid, 'view', userid)
    await deleteDoc(docRef)

}


export async function isViewed(postid, slideid, userid){
    const docRef = doc(db, 'posts', postid, 'slides', slideid, 'view', userid)
    const querySnapshot = await getDoc(docRef)
    if (querySnapshot.exists()){
        return true
    } else {
        return false
    }
}
