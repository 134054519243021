import { useState } from "react"

import {ReactComponent as SignOutIcon} from '../icons/logout.svg'
import {ReactComponent as MenuIcon} from '../icons/menu.svg'
import '../styles/navbar.css'

export default function Navbar (props){
    const [showLogout, setShowLogout] = useState(false)

    const LogOutItem = (props)=>{
        return(
            <div id="logout-container" onClick={props.handleClick}>
                <p className="user-displayname">{props.username}</p>
                <div id="logout-button">
                    <SignOutIcon/>
                    <p>Logout</p>
                </div>
            </div>
        )
    }

    const toggleShowLogout = ()=>{
        setShowLogout(prev=>!prev)
    }

    return(
        <div id="navbar-verwaltung">
            <div className="navbar-container-left">
                <MenuIcon id="menu-icon" onClick={props.toggleSidebar}/>    
                {props.caption && <h3 id='caption-myposts'>{props.caption}</h3>  }
            </div>
            <div className="user-container" onClick={toggleShowLogout}>
                <img className="user-photo" src={props.user.photoURL || '/noUser.png'} alt="profile" referrerPolicy="no-referrer"/>
            </div>
            {showLogout && <LogOutItem handleClick={props.logout} username={props.user.displayName}/>}
        </div>
    )
}