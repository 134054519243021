
import { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom"
import { isMobile } from 'react-device-detect'
import '../styles/overview.css'

import { auth } from '../db/firebase'
import { useAuthState } from 'react-firebase-hooks/auth'
import { getAllMyPosts } from '../services/posts'

import {ReactComponent as EnterIcon} from '../icons/enter.svg'
import {ReactComponent as NewIcon} from '../icons/new.svg'


export default function MyPosts(){
    const [user] = useAuthState(auth)
    const [myPosts, setMyPosts] = useState({})
    // const [loading, setLoading] = useState(true)
    const navigate = useNavigate()

    useEffect(()=>{

        if (!user){return}
        (async()=>{
            const p = await getAllMyPosts(user.uid)
            setMyPosts(p)
        })()

    },[user])

    const handleClick = (postid)=>{
        navigate(`/verwaltung/posts/${postid}`)
    }


    return(
        <>
            <div id='myposts-actions'>
                <button id='btn-neuer-beitrag' onClick={()=>{navigate('/verwaltung/neuer-beitrag')}}><NewIcon/>Neuer Beitrag</button>
            </div>    
            <div id='myposts-captions'>
                <p className='status'></p>
                <p className='pin'>Pin/ID</p>
                <p className='title'>Titel</p>
                <EnterIcon style={{visibility: 'hidden'}} className='enter-icon-ubersicht'/>
            </div>
            <div id='myposts-container'>
                {myPosts[0] && myPosts.map((p) => {
                    return <PostItem key={p.id} id={p.id} title={p.title} active={p.active} handleClick={handleClick}/>
                })}
                {!myPosts[0] && <div className='myposts-item-blank'>Keine Beiträge...</div>}
            </div>
        </>
    )
}

const PostItem = (props)=>{
    return(
        <div className='myposts-item' onClick={()=>{props.handleClick(props.id)}}>
            <div className='myposts-item-active'>
                {props.active ? <Active/> : <Inactive/>}
            </div>
            <p className='myposts-item-id'>{props.id}</p>
            <p className='myposts-item-title'>{props.title}</p>
            <EnterIcon className='enter-icon-ubersicht'/>
        </div>
    )
}

const Active = ()=>{
    return <div className='active-container'><div className='active-point'></div>{isMobile ? '' : 'Aktiv'}</div>
}

const Inactive = ()=>{
    return <div className='inactive-container'><div className='inactive-point'></div>{isMobile ? '' : 'Inaktiv'}</div>
}