export class Slides {
    constructor(){
        this.slide = []
    }

    addSlide(verse, type, id, fontColor, fontSize, index, removed){
        const newslide = new Slide(verse, type, id, fontColor, fontSize, index, removed)
        this.slide.push(newslide)
    }

    sort(){
        this.slide = this.slide.sort((a,b) => (a.index > b.index) ? 1 : ((b.index > a.index) ? -1 : 0))
    }

    
}

// Single Slide ......
export class Slide {
    constructor(verse, type, id, fontColor, fontSize, index, removed){
        this.verse = verse
        this.type = type
        this.id = id
        this.index = index
        this.fontColor = fontColor
        this.fontSize = fontSize
        this.removed = removed
    }
    
    toRecord(){
        return {
            verse: this.verse,
            type: this.type,
            id: this.id,
            fontColor: this.fontColor,
            fontSize: this.fontSize,
            index: this.index,
            removed: this.removed
        };
    }
}