import {
    doc, 
    setDoc, 
    collection, 
    getDoc, 
    getDocs,
    query,
    where,
    limitToLast,
    orderBy, 
} from 'firebase/firestore'
import {db} from '../db/firebase'
import { Slide, Slides } from '../models/slide.model'

// ............ Add all slides to Post 
export async function addSlidesToPost(postid, slides){
    await Promise.all(slides.slide.map(async(slide)=>{
        const jsonSlide = slide.toRecord()
        await setDoc(doc(db, 'posts', postid, 'slides', slide.id), jsonSlide, {merge: true})
    }))
}

// ............ Get all slides from a post (id)
export async function getSlides(postid){
    
    const q = query(collection(db, 'posts', postid, 'slides'), where('removed', '==', false))
    const qSnap = await getDocs(q)
    let s = new Slides()
    qSnap.forEach((doc) => {
        let data = doc.data()
        s.addSlide(data.verse, data.type, data.id, data.fontColor, data.fontSize, data.index, data.removed)
    })

    s.sort()
    return s
}


async function getNextSlideId(postid, actualIndex){
    const colRef = collection(db, 'posts', postid, 'slides')
    const q = query(colRef, where('index', '>', actualIndex), where('removed', '==', false), orderBy('index', 'desc'), limitToLast(1));
    
    try {
        const snap = await getDocs(q)
        return !snap.empty ? snap.docs[0].data().id : null
    } catch (error) {
        return null
    }
}



export async function getSingleSlide(postid, slideid){   // with next slide id, to navigate
    const docRef = doc(db, 'posts', postid, 'slides', slideid)
    const querySnapshot = await getDoc(docRef)
    const s = querySnapshot.data()
    
    if (querySnapshot.exists()){    
        
        const slide = new Slide(s.verse, s.type, s.id, s.fontColor, s.fontSize, s.index, s.removed) 
        if (slide.index >= 0){  
            const nextId = await getNextSlideId(postid, slide.index)
            return {
                slide: slide,
                nextSlideId: nextId
            }
        } else {
            // No index, error
            return undefined
        }
    } else {
        // No snap, error
        return undefined
    }
}