import { useEffect, useState } from 'react'

import ActionPanel from './ActionPanel'
import NoPost from '../pages/NoPost'
import Instructions from './Instructions'
import { getSlides } from '../services/slides'
import ToolTip from './ToolTip'

export default function SlidesController(props){
    const user = props.user
    const post = props.post
    const [slides, setSlides] = useState({})
    const [containerSlides, setContainerSlides] = useState([])
    const [adminMode, setAdminMode] = useState(false)
    const [notFound, setNotFound] = useState(false)
    const [notFoundMessage, setNotFoundMessage] = useState('Ungültige Beitragsdaten')
    // const [isLoading, setIsLoading] = useState(true)

    const getNextSlide = (actualSlideIndex)=>{
        
        // If user = google.user = adminMode = No swipeUp and no instructions (all slides are loaded)
        if (adminMode){return false}

        // Load first Slide
        if (!containerSlides[0]){
            setContainerSlides([...containerSlides, slides.slide[0]])
            return false
        }

        if (slides.slide.length === containerSlides.length){return false} //check if last
        if (!containerSlides[actualSlideIndex + 1]){
            setContainerSlides([...containerSlides, slides.slide[containerSlides.length]]) // Load next Slide
            return true
        }
    }

    useEffect(()=>{
        (async()=>{
            try {
                // ...... Get all slides data
                let s = await getSlides(post.id)
                setSlides(s)

                // case **Anonymous**, load first one slide, if liked/view/question, load next (useEffect from ActionPanel)
                if (user.isAnonymous){
                    setContainerSlides([...containerSlides, s.slide[0]])
                    return
                }
    
                // case **Google User**, load all Slides + set admin mode true
                if (!user.isAnonymous && user.uid === post.created_by){
                    setAdminMode(true)
                    setContainerSlides(s.slide)
                } else if (!user.isAnonymous) {
                    // RARE, a logged google user, accessing a post from another user
                    setContainerSlides([...containerSlides, s.slide[0]])
                }            
            } catch (error) {
                setNotFound(true)
                setNotFoundMessage(error.message)
            } finally {
                // setIsLoading(false)
            }
        })()

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    
    return(
        <>
        <div id='structure'>
            <div id="structure-snapscroll">
            {adminMode && 
                <div id='admin-mode-warning'>
                    Admin-Modus
                    <ToolTip right text={`Sie befinden sich im Administratormodus, 
                                    da Sie angemeldet sind. In diesem Modus sind alle Slides bereits freigegeben. 
                                    Um die Erfahrung eines normalen Benutzers zu erhalten, 
                                    gehen Sie bitte zu: Verwaltung > Oben rechts > Abmelden`}  />
                </div>}
            {containerSlides && containerSlides.map((s, i)=>{
                return slides.slide && 
                    <SlideView 
                        getNextSlide={getNextSlide}
                        key={s.id}
                        postid={post.id}
                        slide={s}
                        src={post.urlPicture}
                        adminMode={adminMode}
                        user={user}
                        first={i === 0 ? true : false}/>   
        
            })}
            {notFound && <NoPost error={notFoundMessage}/>}
            </div>
        </div>
        </>
    )
}

const SlideView = ({slide, adminMode, getNextSlide, src, postid, user, first})=>{
    const [swipeUp, setSwipeUp] = useState(false)
    const [wasWarned, setWasWarned] = useState(false)
    const [showInstructions, setShowInstructions] = useState(false)


    // ............ Swipe Up
    const showSwipeUp = () => {
        setSwipeUp(true);
        setTimeout(() => {
            setSwipeUp(false)
            setWasWarned(true)
        }, 3500);
    }

    // ........... Check if user clicked on like, view or question button
    const handleGetNextSlide = ()=>{
        if (adminMode){return}
        const showWarning = getNextSlide(slide.index)

        if (!wasWarned && showWarning){
            showSwipeUp()
        }    
    }

    useEffect(()=>{
        if (first){
            setShowInstructions(true)
        }
    },[first])
    

    return(
        <>
        <div data-testid={slide.id} className={slide.type + '-slide slide'} loading='lazy'
            style={{color: slide.fontColor, backgroundImage: `url(${src})`}}>
            
            <Instructions isVisible={showInstructions}/>

            <p className='slide-vers' style={{fontSize: `${slide.fontSize}rem`}}>{slide.verse}</p>

            <ActionPanel
                postid={postid}
                slideid={slide.id}
                getNextSlide={handleGetNextSlide} 
                swipeUp={swipeUp}
                user={user}/>
        </div>
        </>
    )
}




