import { getPost } from '../services/posts'
import { signInAnonym , useAuth, authChanged} from '../services/firebaseAuth'

import { useState, useEffect } from 'react'
import '../styles/posts.css'
import {useSearchParams} from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import SlidesController from '../components/SlidesController';
import NoPost from './NoPost'
import Loading from '../components/Loading'

export default function PostView(){
    const [user] = useAuth() || [{}]
    const [post, setPost] = useState({})
    const [notFound, setNotFound] = useState(false)
    const [notFoundError, setNotFoundError] = useState(undefined)
    const [loading, setLoading] = useState(true)

    // Get id on url query
    const [searchParams] = useSearchParams();
    const actualId = searchParams.get('id')

    useEffect(()=>{
        
        //.......... Try to sign in Anonym
        const listen = authChanged((user) => {
            if (user){
                // logged in :)
            } else {
                try {
                    (async()=>{
                        await signInAnonym()
                    })()
                } catch (error) {
                    toast.error(error)
                }
            }
        })

        //........... Load slides from post id
        if (user){
            if (actualId){
                (async()=>{
                    try {
                        // get post data
                        let p = await getPost(actualId)
                        // let p = {id: '123', title: 'title', created_by: 'user', active: true, backgroundfilename: '1.jpg'}
                        
                        // inactive, except for postCreator
                        if (!p.active && user.uid !== p.created_by){
                            throw new Error('INACTIVE')
                        }
                        
                        await p.getUrlBackground(`/backgrounds/${p.backgroundfilename}`) //get background picture
                        setPost(p)
                        
                        document.title = p.title
                        setLoading(false)
                            
                    } catch (error) {
                        setNotFoundError(error)
                        setNotFound(true)
                        setLoading(false)
                    }
                })()
            } else {
                setNotFoundError(`PIN`)
                setNotFound(true)
                setLoading(false)
            }
        } else {
            setLoading(true)
        }
        
        return () => {
            if (listen) {
              listen()
            }
        };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[user])

    return(
        <>
            {loading && <Loading/>}
            {post && post.id && post.urlPicture && <SlidesController post={post} user={user}/>}
            {notFound && <NoPost error={notFoundError} />}
            <ToastContainer/>        
        </>
    )
}
