import '../styles/msgboxModal.css'

export default function MsgBoxModal({funcConfirm, funcCancel, btnConfirmText, btnCancelText, msg, colorBtnConfirm}){


    const handleClickOutside = (e)=>{
        if (e.target === e.currentTarget){
            funcCancel()
        }
    }

    return (
        <>
        <div id="container-blur-msgbox" onClick={(e)=>{handleClickOutside(e)}}>
            <div id='s-c-msgbox'>
                <p>{msg}</p>
                <div id='c-msgbox-buttons'>
                    <button id='msgbox-confirm' onClick={funcConfirm} style={{backgroundColor: colorBtnConfirm}}>{btnConfirmText}</button>
                    <button id='msgbox-cancel' onClick={funcCancel}>{btnCancelText}</button>
                </div>
            </div>
        </div>
        </>
    )
}