import '../styles/viewPost.css'
import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { deletePost, getPost, isThePinAvailable, resetStatsPost, setPostStatus } from '../services/posts';
import { ToastContainer, toast } from 'react-toastify';
import { getSlides } from '../services/slides';

import {ReactComponent as EnterIcon} from '../icons/enter.svg'

import { LoadingDivModal } from './LoadingDiv';
import { NavPost } from './Navigator';
import MsgBoxModal from './MsgBoxModal';


export default function ViewPost(){

    let { postid } = useParams()
    const navigate = useNavigate()
    const [post, setPost] = useState({})
    const [slides, setSlides] = useState({})
    const qrCodeValue = `${window.location.protocol}//${window.location.hostname}/post?id=${postid}`
    
    const [isLoading, setIsLoading] = useState(true)
    const [showMsgBoxDelete, setShowMsgBoxDelete] = useState(false)
    const [showMsgBoxReset, setShowMsgBoxReset] = useState(false)

    useEffect(()=>{
        (async()=>{
            try {
                // check if pin exists
                const invalidPin = await isThePinAvailable(postid)
                if (invalidPin){
                    navigate('/verwaltung')
                    return
                }

                // get data from post and slides
                const [rPost, rSlides] = await Promise.all([getPost(postid), getSlides(postid)])
                setPost(rPost)
                setSlides(rSlides.slide)
                setIsLoading(false)
                
            } catch (error) {
                toast.error('Fehler, Zugriff',{draggable: false})
                setIsLoading(false)
            }
        })()

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const handleGoBack = ()=>{
        navigate('/verwaltung')
    }

    const handleClickSlide = (slideid)=>{
        navigate(`/verwaltung/posts/${postid}/slides/${slideid}`)
    }

    const handleOpenLink = ()=>{
        window.open(qrCodeValue, "_blank", "noreferrer");
    }

    const handleCopyLink = ()=>{
        navigator.clipboard.writeText(qrCodeValue)
    }

    const confirmBoxReset = ()=>{
        setShowMsgBoxReset(prev => !prev)
    }

    const resetPost = async()=>{
        try {
            setIsLoading(true)
            await resetStatsPost(postid)
            setIsLoading(false)
            setShowMsgBoxReset(false)
            toast.success('Beitrag wurde erfolgreich zurückgesetzt', {draggable: false})
        } catch (error) {
            setIsLoading(false)
            setShowMsgBoxReset(false)
            toast.error('Fehler Reset Post', {draggable: false})
        }
    }

    const confirmBoxDelete = ()=>{
        setShowMsgBoxDelete(prev => !prev)
    }

    const removePost = async()=>{
        try {
            setIsLoading(true)
            await deletePost(postid)
            navigate('/verwaltung')
        } catch (error) {
            setIsLoading(false)
            toast.error('Fehler Delete Post', {draggable: false})
        }
    }

    const handleChangeStatus = async()=>{
        const newStatus = !post.active
        setPost(prevPost => ({
            ...prevPost,
            active: newStatus
        }))

        try {
            await setPostStatus(post.id, newStatus)
        } catch (error) {
            setPost(prevPost => ({
                ...prevPost,
                active: !newStatus
            }))
            toast.error("Fehler: " + error.message)
        }

    }

    const actionsBtnOption = {
        handleOpenLink: ()=>{return handleOpenLink()},
        handleCopyLink: ()=>{return handleCopyLink()},
        handleReset: ()=>{return confirmBoxReset()},
        handleDelete: ()=>{return confirmBoxDelete()},
    }
    

    return (
        <>
            {post.id && 
                <NavPost
                    handleGoBack={handleGoBack} 
                    previousPage={'Übersicht'} 
                    title={post.id + ' - ' + post.title} 
                    functions={actionsBtnOption}
                    qrCodeValue={qrCodeValue}
                    status={post.active}
                    handleChangeStatus={handleChangeStatus}/>}
            
            {slides[0] && 
                <div id='post-slides-ubersicht'>
                    {slides[0] && slides.map((s, index)=>{
                        return <div key={index} className='slides-ubersicht-item' onClick={()=>{handleClickSlide(s.id)}}>
                                    <p>{s.verse}</p>
                                    <EnterIcon className='enter-icon'/>
                                </div>
                    })}
                </div>
            }

            {isLoading && <LoadingDivModal/>}

            {showMsgBoxDelete && <MsgBoxModal btnConfirmText={'Ja'} btnCancelText={'Abbrechen'} funcCancel={confirmBoxDelete}
                                        funcConfirm={removePost} msg={'Wollen Sie wirklich löschen?'} 
                                        colorBtnConfirm={'#b34242'}/>}
            {showMsgBoxReset && <MsgBoxModal btnConfirmText={'Ja'} btnCancelText={'Abbrechen'} funcCancel={confirmBoxReset}
                                        funcConfirm={resetPost} msg={'Wollen Sie alle Likes, Views und Fragen löschen?'} 
                                        colorBtnConfirm={'#0099ff'}/>}

            <ToastContainer/>
            
        </>
    )
}


