import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import '../styles/homepage.css'

import {ReactComponent as EnterIcon} from '../icons/enter.svg'
import { QRCodeCanvas } from 'qrcode.react'

export default function Homepage(){
    const [pin, setPin] = useState('')
    const navigate = useNavigate()
    const qrCodeValue = `${window.location.protocol}//${window.location.hostname}/post?id=0608`

    const goToLogin = ()=>{
        navigate('/login')
    }

    const handleStartClick = ()=>{
        if (pin){
            navigate(`/post?id=${pin}`)
         }
    }

    const handleKeyPress = (e)=>{
        if (e === 'Enter'){
            handleStartClick()
        }
    }

    return (
        <>
        <div id='container-homepage'>

        <div id='structure-homepage' style={{backgroundImage: `url('/wallpaper.jpg')`}}>

            <div className='section-hp fullscreen-section'>

                <div id='container-nav-hp'>
                    <div id='search-pin-container'>
                        <input type="text" placeholder='Pin/ID eingeben...' 
                                value={pin} onChange={(e)=>{setPin(e.target.value)}} 
                                onKeyUp={(e)=>{handleKeyPress(e.key)}}
                                autoComplete='off'/>
                        <button id='btn-go' onClick={handleStartClick}>Go</button>
                    </div>
                    <ButtonHomepage text={'Login'} click={goToLogin}/>
                </div>
                <div id='logo-scrollb'>
                    <img src="/scrollblogo.png" alt="scrollb-logo" />
                </div>
                <h2 id='title-hp'>scroll' mit deiner Gruppe durch einen Bibeltext</h2>
                <div id='container-smartphone'>
                    <div id='smartphone' style={{backgroundImage: `url('/smartphone.png')`}}>
                        <video 
                            src="/scrollbphonevideo.mp4"
                            autoPlay
                            loop
                            muted
                            playsInline
                            preload='auto'
                            className='video-scrollb'
                            itemType='video/mp4'
                        ></video>
                        
                        
                    </div>
                </div>

            </div>

            <div className='section-hp color-section'>

                <h2>Die Bibellesemethode für Digital Natives</h2>
                <h4 id='subtitle-hp'>Wie auf Social Media scrollen die Teilnehmer bei dieser Methode. 
                    Vers für Vers swipen wir durch den selbst ausgewählten Bibeltext, 
                    Liken einzelnen Sätze oder stellen Fragen, die später beantwortet werden. 
                </h4>

                <div id='scan-title'>
                    <h3>Probiere es einfach kurz selbst:</h3>
                </div>
                <QRCodeCanvas 
                    className='qrcode-share' 
                    value={qrCodeValue}
                    includeMargin={true}
                    size={128}
                    level={"H"}
                    imageSettings={{
                        src: "/favicon.ico",
                        x: undefined,
                        y: undefined,
                        height: 24,
                        width: 24,
                        excavate: true,
                    }}/>
                <a className='link-beispielbeitrag' href={qrCodeValue}>Link</a>

            </div>

            <div className='section-hp'>
                
                <h2 className='video-title-hp'>Was ist scrollb?</h2>
                <iframe className='youtube-embed' width="560" height="315" 
                    src="https://www.youtube-nocookie.com/embed/U_jnq6dzLNo?si=WbrSSCIsfshsUdXA" 
                    title="YouTube video player" frameBorder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; " 
                    allowFullScreen>
                </iframe>

            </div>

            <div className='section-hp color-section'>

                <h2 id='title-schritt-hp'>6 Schritte der Scroll Bibellese Methode</h2>
                <div id='container-schritte'>
                    <Schritt number={'1.'} text={'QR Code scannen'}/>
                    <Schritt number={'2.'} text={'Die erste Slide lesen'}/>
                    <Schritt number={'3.'} text={'Interagiere um weiter zu scrollen'}/>
                    <Schritt number={'4.'} text={'Alle Slide lesen und agieren'}/>
                    <Schritt number={'5.'} text={'Fragen und Likes auswerten'}/>
                    <Schritt number={'6.'} text={'Andacht zum Text'}/>
                </div>

            </div>

            <div className='section-hp'>

                <div className='video-title-hp video-title-div'>
                    <h2>So erstellst du selbst ein scrollb mit
                        eigenem Text für deine Gruppe</h2>
                </div>
                <iframe className='youtube-embed' width="560" 
                    src="https://www.youtube-nocookie.com/embed/hmLeSOh9nVw?si=wBkfGPQldkGGQVv-" 
                    title="YouTube video player 2" frameBorder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope;" 
                    allowFullScreen>
                </iframe>

            </div>

            <div className='section-hp color-section'>

                <h2 id='title-faq'>FAQ</h2>
                <div id='container-faq'>
                    <FAQItem question={'Mit wie vielen Teilnehmern kann ich diese Methode nutzen?'}
                        answer={'Die Methode kann in einer Kleingruppe aber auch mit mehreren hundert Personen genutzt werden.'}/>
                    <FAQItem question={'Darf ich die Methode frei verwenden?'}
                        answer={'Ja, du kannst sie für die Gemeindearbeit nutzen ohne sie kommerziell einzusetzen'}/>
                    <FAQItem question={'Was kostet die Nutzung?'}
                        answer={'Die Nutzung ist kostenlos. Wenn du uns unterstützen möchtest, dann freuen wir uns über eine Spende'}
                        link={'https://gemeinschaft-muenchen.de/kontakt'}
                        textLink={'LINK'}/>
                    <FAQItem question={'Müssen die Teilnehmenden sich einloggen oder benötigen ein Konto?'}
                        answer={'Nein, die User können einfach via QR Code oder Link auf die Bibellese zugreifen. Wer die Methode erstellt, muss sich für die Erstellung einloggen und ein Konto eröffnen.'}/>
                    <FAQItem question={'Wer hat scrollb entwickelt?'}
                        answer={'Scrollb wird von der Ev. Gemeinschaft München-Bogenhausen zur Verfügung gestellt. Mehr Informationen zu der Gemeinde findest du hier:'}
                        link={'https://gemeinschaft-muenchen.de'} textLink={'LINK'}/>
                    <FAQItem question={'Wo kann ich etwas spenden für die Nutzung von scrollb?'}
                        answer={'Wir freuen uns über Spenden an die Ev. Gemeinschaft München-Bogenhausen. Alle Informationen dazu findest du hier:'}
                        link={'https://gemeinschaft-muenchen.de/kontakt'} textLink={'LINK'}/>
                    <FAQItem question={'Kontakt:'}
                        answer={'Sem Dietterle ist Ansprechpartner. Kontakt aufnehmen:'}
                        link={'https://gemeinschaft-muenchen.de/pastoren'} textLink={'LINK'}/>
                
                </div>
            </div>

            <a id='datenschutz' href="https://gemeinschaft-muenchen.de/impressum">Impressum/Datenschutz</a>

        </div>
        </div>
        </>
    )
}


const Schritt = ({number, text})=>{
    return (
        <div className='single-schritt'>
            <p className='s-s-n'>{number}</p>
            <p className='s-s-t'>{text}</p>
        </div>
    )
}


const FAQItem = ({question, answer, link, textLink})=>{
    return (
        <div className='faq-item'>
            <h2>{question}</h2>
            <h3>{answer}</h3>
            {link && <a href={link}>{textLink}</a>}
        </div>
    )
}   

const ButtonHomepage = ({text, click})=>{
    return (
        <button onClick={click}>{text}<EnterIcon className='enter-icon-hp'/></button>
    )
}