import {ReactComponent as BackIcon} from '../icons/back.svg'
import {ReactComponent as SettingsIcon} from '../icons/settings.svg'
import {ReactComponent as SaveIcon} from '../icons/save.svg'
import {ReactComponent as ShareIcon} from '../icons/share.svg'
// import {ReactComponent as OpenLinkIcon} from '../icons/openlink.svg'

import '../styles/navigator.css'
import { useRef, useState } from 'react'
import {SettingsBox, ShareBox} from './PostModalBoxes'
import ToolTip from './ToolTip'

export function NavSlides(props){

    
    return (
        <div id='nav-slides-container'>
            <div id='nav-btn-goback' onClick={props.handleGoBack}>
                <BackIcon className='back-icon'/>
                <p>{props.previousPage}</p>
            </div>
            <div>&nbsp;</div>
            {props.nextSlideId &&
                 <div id='nav-btn-next' onClick={props.handleNextSlide}>
                    <p>Nächste</p>
                    <BackIcon className='next-icon'/>
                </div>
            }

            <div id='nav-slides-title-wrapper'>
                <p>{props.title}</p>
            </div>
        </div>
    )
}

export function NavPost({handleGoBack, previousPage, title, functions, qrCodeValue, status, handleChangeStatus}){
    const [shareBox, setShareBox] = useState(false)
    const [editBox, setEditBox] = useState(false)
    const refBtnShare = useRef(null)
    const refBtnSettings = useRef(null)
    
    const toggleShareBox = ()=>{
        setShareBox(prev => !prev)
    }
    
    const toggleEditBox = ()=>{
        setEditBox(prev => !prev)
    }

    return (
        <div id='nav-posts-container'>
            <div id='nav-btn-goback' onClick={handleGoBack}>
                <BackIcon className='back-icon'/>
                <p>{previousPage}</p>
            </div>
            <div id='nav-posts-title-wrapper'>

                <p>{title}</p>
                <div id='container-ref-actions' style={{position: 'relative', display: 'inline-flex', alignItems: 'center', gap: '8px'}}>
                    <button className='n-p-btn-share n-p-btn' onClick={toggleShareBox} ref={refBtnShare}>
                        <ShareIcon className='icon-share'/>
                        Teilen
                    </button>
                    <button className='n-p-btn-settings n-p-btn' onClick={toggleEditBox} ref={refBtnSettings}>
                        <SettingsIcon className='icon-settings'/>
                        Einstellungen
                    </button>

                    

                    {shareBox && 
                        <ShareBox 
                            close={toggleShareBox} 
                            refButton={refBtnShare}
                            qrCodeValue={qrCodeValue}
                            onCopyLink={functions.handleCopyLink}/>}
                    {editBox && 
                        <SettingsBox 
                            close={toggleEditBox} 
                            refButton={refBtnSettings}
                            onClean={functions.handleReset}
                            onDelete={functions.handleDelete}/>}
                </div>

            </div>
            <div id='container-status'>
                <div>Status:
                    <ToolTip right 
                        text={`Legt den Status eines Beitrags fest. Ein deaktivierter Beitrag ist 
                                für niemanden mehr sichtbar (außer für Sie). Nützlich für eine Präsentation, 
                                bei der Sie möchten, dass Benutzer die Nutzung nicht mehr fortsetzen`}/>
                </div>
                
                <input type="checkbox" id="switch-status" defaultChecked={status} onChange={handleChangeStatus}/>
                <label className='label-switch' htmlFor="switch-status"></label>
            </div>
        </div>
    )
}

export function NavNewPost({previousPage, handleGoBack, handleSave, unsavedChanges}){

    const handleBeforeGoBack = ()=>{
        if (unsavedChanges){
            // implement !!!!
        }
        handleGoBack()
    }

    // const handleCancel = ()=>{
    //     handleGoBack()
    // }

    return (
        <div id='nav-newpost-container'>
            <div id='nav-btn-goback' onClick={handleBeforeGoBack}>
                <BackIcon className='back-icon'/>
                <p>{previousPage}</p>
            </div>
            {/* <button id='newpost-btn-cancel' onClick={handleCancel}>Abbrechen</button> */}
            <button id='newpost-btn-save' onClick={handleSave}><SaveIcon className='save-icon'/>Speichern</button>
        </div>
    )
}
