import '../styles/toolTip.css'
import {ReactComponent as HelpIcon} from '../icons/help.svg'
import { useEffect, useRef } from 'react'

export default function ToolTip({text, right, noMargin}){
    const ref = useRef(null)

    useEffect(()=>{
        if (right){
            ref.current.style.transform = 'translateX(-100%)'
        }
    },[ref, right])

    return (
        <div className='tooltip-container'>
            <HelpIcon data-testid="help-icon" className='help-icon' style={noMargin && {margin: '0'}}/>
            <div ref={ref} className='tooltip-text' data-testid="tooltip-text">
                <p>{text}</p>
            </div>
        </div>
    )
}