import '../styles/sidebar.css'
export default function Sidebar (props){

    const handleClickSidebar = (address)=>{
        props.handleNavigate(address)
        props.toggleSidebar()
    }

    const handleClickOutsideSidebar = (e)=>{
        if (e.target === e.currentTarget){
            props.toggleSidebar()
        }
    }

    return (
            <>
                <div ref={props.refSidebar} className="sidebar">
                    <div className="sidebar-btn" onClick={()=>{handleClickSidebar('/verwaltung')}}><p>Meine Beiträge</p></div>
                    <div className="sidebar-btn" onClick={()=>{handleClickSidebar('/verwaltung/neuer-beitrag')}}><p>Neuer Beitrag</p></div>
                </div>
                {props.state && <div id="invisble-block-sidebar" ref={props.refSidebarOut} onClick={(e)=>{handleClickOutsideSidebar(e)}}></div>}
            </>
    )
}

