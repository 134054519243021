import { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from "react-router-dom"
import { auth } from '../db/firebase'
import { useAuthState } from 'react-firebase-hooks/auth'
import { isMobile } from 'react-device-detect'
import '../styles/newPost.css'
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';

import { storage } from '../db/firebase'
import { getBlob, ref, uploadBytesResumable } from "firebase/storage"
import { v4 as uuidv4 } from 'uuid';

import { addPost, getPost, isThePinAvailable } from '../services/posts'
import { getSlides } from '../services/slides'
import { Post } from '../models/post.model'
import { Slides } from '../models/slide.model'

import {ReactComponent as NewWallpaperIcon} from '../icons/newwallpaper.svg'
import {ReactComponent as DeleteIcon} from '../icons/delete_small.svg'
import {ReactComponent as NewIcon} from '../icons/new.svg'
import {ReactComponent as EditIcon} from '../icons/edit_small.svg'
import {ReactComponent as SaveIcon} from '../icons/save.svg'

import { LoadingDivModal } from './LoadingDiv'
import { NavNewPost } from './Navigator'
import ToolTip from './ToolTip'


export default function NewPost(){
    const refID = useRef(null)
    const [id, setId] = useState('')
    const [title, setTitle] = useState('')
    const [image, setImage] = useState('')
    const [urlImage, setUrlImage] = useState('')
    const [slides, setSlides] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    const [lastImageFile, setLastImageFile] = useState(null)
    const [lastImageUrl, setLastImageUrl] = useState('')

    const [user] = useAuthState(auth)
    const navigate = useNavigate()
    const {postid} = useParams()
    
    const savePost = async()=>{
        try {
            setIsLoading(true)
            
            const errors = await validateNewPost()
            if (errors.length){
                return
            }
            
            // remover antes do firebase os slides removidos !!!!!!!!!!!!!!!!!

            const nSlides = new Slides()
            slides.forEach((s, index)=>{
                nSlides.addSlide(s.verse, "normal", postid ? s.id : uuidv4(), s.fontColor, s.fontSize, index, s.removed)
            })
                
            const newpost = new Post(title, image.name, id, user.uid, true)
            await addPost(newpost, nSlides)
            
            // upload, only if NOT a model background
            if (!urlImage.startsWith('/wallpapers/')){
                await uploadToFirebase()
            }

            clearNewpost()
            navigate('/verwaltung')

        } catch(error) {
            toast.error("Add new Post Error")
        } finally {
            setIsLoading(false)
        }
        
    }

    const validateNewPost = async()=>{
        const errors = []
        if (!title){errors.push(toast.warning('Bitte schreiben Sie einen Titel'))}
        if (!id){errors.push(toast.warning('Bitte legen Sie eine Pin/ID fest'))}
        if (!urlImage){errors.push(toast.warning('Bitte wählen Sie einen Hintergrund'))}
        if (!slides[0]){errors.push(toast.warning('Bitte fügen Sie mindestens eine Slide hinzu'))}

        if (!postid){
            const isAvailable = await isThePinAvailable(id)
            if (!isAvailable){
                errors.push(toast.warning("Beitrags-Pin/ID nicht verfügbar!"))
            }
        }
        return errors
    }

    const clearNewpost = ()=>{
        setTitle('')
        setId('')
        setSlides([])
        setImage(null)
        setUrlImage('')
    }

    const uploadToFirebase = async()=>{
        
        if (image == null)
            return;
        const storageRef = ref(storage,`/backgrounds/${image.name}`)
        const uploadTask = uploadBytesResumable(storageRef, image);
        uploadTask.on("state_changed", (sucess)=>{
            setImage('')
        }, (error)=>{
            toast.error('Upload Picture Error', {draggable: false})
        })
    }

    const updateStateFile = (file)=>{
        setImage(file)
    }

    const updateStateFileUrl = (url)=>{
        setUrlImage(url)
    }

    const addSlide = (verse, fontColor, fontSize)=>{
        setSlides([
            ...slides,
            {   
                id: uuidv4(), 
                verse: verse,
                fontColor: fontColor,
                fontSize: fontSize,
                removed: false
            }
        ])
    }

    const updateSlide = (id, verse, fontColor, fontSize)=>{
        const index = slides.findIndex(s => s.id === id)
        const newSlides = [...slides]
        newSlides[index] = {...newSlides[index], verse: verse, fontColor: fontColor, fontSize: fontSize}
        setSlides(newSlides)
    }

    const removeSlide = (id)=>{

        const index = slides.findIndex(slide => slide.id === id)
        if (index === -1){
            toast.error('Fehler, Slide ID nicht gefunden. -> F5 ')
            return
        }

        const s = [...slides]
        s[index] = {...slides[index], removed: true}
        setSlides(s)

    }

    const handleIdKeyUp = async()=>{

        try {
            const isAvailable = await isThePinAvailable(id)
            if (isAvailable){
                refID.current.style.backgroundColor = '#beffbe'
            } else {
                refID.current.style.backgroundColor = '#ffbebe'
            }
        } catch (error) {
            toast.error('Error check Pin', {draggable: false})
        }
    }

    const handleGoBack = ()=>{
        navigate('/verwaltung')
    }

    const getContentType = (extension)=>{
        const types = {
          jpg: 'image/jpeg',
          png: 'image/png',
          bmp: 'image/bmp'
        }
      
        return types[extension.toLowerCase()] || 'application/octet-stream'
    }

    const updateLastImageFile = (file)=>{
        setLastImageFile(file)
    }

    const updateLastImageUrl = (url)=>{
        setLastImageUrl(url)
    }

    useEffect(()=>{

        // check if edit mode is ON
        if (postid){
            
            (async()=>{
                try {

                    setIsLoading(true)

                     // retrieve post data
                    const [rPost, rSlides] = await Promise.all([getPost(postid), getSlides(postid)])
                    setId(rPost.id)
                    setTitle(rPost.title)

                    // get background url | blob => file

                        // check if bg is 1,2,3,4,5.jpg -- VORLAGEN
                        if (rPost.backgroundfilename === '1.jpg' || rPost.backgroundfilename === '2.jpg' || 
                            rPost.backgroundfilename === '3.jpg' || rPost.backgroundfilename === '4.jpg' ||
                            rPost.backgroundfilename === '5.jpg'){
                            
                            const response = await fetch(`/wallpapers/${rPost.backgroundfilename}`)
                            const blob = await response.blob()
                            const extension = rPost.backgroundfilename.split('.').pop()
                            const file = new File([blob], rPost.backgroundfilename, { type: getContentType(extension) })
                            setImage(file)
                            setUrlImage(`/wallpapers/${rPost.backgroundfilename}`)

                        } else {
                        
                        // -- UPLOADED wallpapers
                            await rPost.getUrlBackground(`/backgrounds/${rPost.backgroundfilename}`) //get background url
                            const bgRef = await rPost.getBackgroundRef(`/backgrounds/${rPost.backgroundfilename}`)
                            const blob = await getBlob(bgRef)
                            const extension = rPost.backgroundfilename.split('.').pop()
                            const file = new File([blob], rPost.backgroundfilename, { type: getContentType(extension) })
                            setImage(file)
                            setUrlImage(rPost.urlPicture)
                            
                            // store backup (CACHE) uploaded/downloaded file
                            setLastImageFile(file)
                            setLastImageUrl(rPost.urlPicture)

                        }
                        
                    // get slides
                    setSlides(rSlides.slide)

                } catch (error) {
                    throw error
                } finally {
                    setIsLoading(false)
                }
            })()
        }
    },[postid])


    useEffect(()=>{
        if (isMobile){
            toast.info('Diese Seite funktioniert auf Mobilgeräten möglicherweise nicht richtig. Am Computer haben Sie ein besseres Erlebnis!', 
                {position: 'top-center', autoClose: false}
            )
        }
    },[])

    return (
        <>
            <NavNewPost 
                previousPage='Übersicht' 
                handleGoBack={handleGoBack} 
                unsavedChanges={id || title || urlImage || slides[0] ? true : false}
                handleSave={savePost}/>

            <div id='newpost-structure'>
                {isLoading && <LoadingDivModal/>}
                
                <div id='newpost-container-top'>
                    <div className='container-new-title'>
                        <label htmlFor="title">Titel:<ToolTip text={'Sichtbar im Browser-Tab und für Sie in der Beitragsliste'}/></label>
                        <input  type="text" name="title" id="title" value={title} 
                                placeholder='Beitragstitel, sichtbar im Browser-Tab...'
                                maxLength='200'
                                minLength='3'
                                onChange={(e)=>{setTitle(e.target.value)}} 
                                autoComplete='off'/>
                    </div>
                    
                    <div className='container-new-id'>
                        <label htmlFor="id">Pin/ID:
                            <ToolTip right={true} text={`Wenn bei manchen der QR Code nicht funktioniert, 
                                            kann über die Website scrollb.gemeinschaft-muenchen.de 
                                            via Pin auch auf die Bibellese zugegriffen werden. Unmöglich zu ändern`}/>
                        </label>
                        <input  type="text" name="id" id="id" autoComplete='off' 
                                minLength='3'
                                maxLength='4'
                                placeholder='100-9999'
                                ref={refID} value={id} 
                                onChange={(e)=>{setId(e.target.value)}} 
                                onKeyUp={handleIdKeyUp}
                                disabled={postid ? true : false}/>
                    </div>

                    <div className='container-new-fileinput'>
                        <label htmlFor="hiddenelement">Hintergrund:</label>
                        <input type="hidden" id="hiddenelement" />
                        <FileUpload 
                            updateStateFile={updateStateFile} 
                            updateStateFileUrl={updateStateFileUrl} 
                            actualImage={image}
                            lastImageFile={lastImageFile}
                            lastImageUrl={lastImageUrl}
                            setLastImageFile={updateLastImageFile}
                            setLastImageUrl={updateLastImageUrl}/>
                        
                    </div>
                </div>

                <AddSlidesContainer 
                    slides={slides} 
                    addSlide={addSlide} 
                    updateSlide={updateSlide}
                    removeSlide={removeSlide}
                    urlImage={urlImage}/>
                
            </div>
            <ToastContainer/>
        </>
        
        
    )
}

const AddSlidesContainer = ({addSlide, slides, updateSlide, removeSlide, urlImage})=>{
    const refScrollRight = useRef(null)

    useEffect(()=>{
        refScrollRight.current?.scrollIntoView({ behavior: "smooth" })
    },[slides])

    return (
        <div id='newslides-container'>
            <div id='newslides-preview'>
                {slides && slides.map((s, i)=>{
                    return !s.removed && 
                        <SingleSlidePreview 
                            key={i} 
                            slide={s} 
                            updateSlide={updateSlide} 
                            removeSlide={removeSlide} 
                            urlImage={urlImage}/>
                })}
                <div ref={refScrollRight}></div>
            </div>
            <AddNewSlide addSlide={addSlide} urlImage={urlImage}/>
            {isMobile && <div>Übersicht hier unten:</div>}
        </div>
    )

}

const SingleSlidePreview = ({updateSlide, slide, removeSlide, urlImage})=>{
    const [editMode, setEditMode] = useState(false)
    
    const handleUpdateSlide = (id, verse, fontColor, fontSize)=>{
        updateSlide(id, verse, fontColor, fontSize)
        closeModal()
    }

    const closeModal = ()=>{
        setEditMode(false)
    }

    return (
        <>
        <div id='newslide-slide' 
            style={{color: slide.fontColor, fontSize: `${slide.fontSize * 0.65}rem`, backgroundImage: `url(${urlImage})`}}>
            <p>{slide.verse}</p>
            <button className='slide-edit-btn' onClick={()=>{setEditMode(true)}}><EditIcon className='newslide-icon-edit'/></button>
            <button className='slide-remove-btn' onClick={()=>{removeSlide(slide.id)}}><DeleteIcon className='newslide-icon-delete'/></button>
            {editMode && <ModalEditSlide slide={slide} handleUpdateSlide={handleUpdateSlide} closeModal={closeModal}/>}
        </div>
        </>
    )   
}

const ModalEditSlide = ({slide, handleUpdateSlide, closeModal})=>{
    const [verse, setVerse] = useState(slide.verse)
    const [fontSize, setFontSize] = useState(slide.fontSize)
    const [fontColor, setFontColor] = useState(slide.fontColor)

    const handleSave = ()=>{
        
         // checks
         if (!verse){
            toast.warning('Bitte schreiben Sie einen Vers')
            return
        }

        if (!fontSize || !fontColor){
            return
        }
        
        handleUpdateSlide(slide.id, verse, fontColor, fontSize)
    }

    const onClickOutside = (e)=>{
        return
        // if (e.target === e.currentTarget){
        //     closeModal()
        // }
    }

    const handleDecreaseFontSize = ()=>{
        let size = fontSize
        if (size > 1){
            let result = size = size - 0.1
            result = +result.toFixed(1)
            setFontSize(result)
        }
    }
    
    const handleIncreaseFontSize = ()=>{
        let size = fontSize
        if (size < 2.5){
            let result = size = size + 0.1
            result = +result.toFixed(1)
            setFontSize(result)
        }
    }

    return (
        <div className='modal-edit-slide-container' onClick={(e)=>{onClickOutside(e)}}>

            <textarea id="newslide-vers" placeholder='Hier ein Vers schreiben...' value={verse}
                        onChange={(e)=>{setVerse(e.target.value)}} 
                        style={{fontSize: `${fontSize * 0.65}rem`, color: fontColor}}>
            </textarea>
            <div id='newslide-font-size'>
                <label htmlFor="font-size-ns">Schriftgröße</label>
                <div>
                    <button onClick={handleDecreaseFontSize}>-</button>
                    <input type="text" id="font-size-ns" value={fontSize} readOnly={true}/>
                    <button onClick={handleIncreaseFontSize}>+</button>
                </div>
            </div>
            <div id='newslide-font-color'>
                <label htmlFor="font-color-ns">Schriftfarbe</label>
                <input type="color" id="font-color-ns" value={fontColor} onChange={(e)=>{setFontColor(e.target.value)}}/>
            </div>
            <button id='newslide-btn-cancel' onClick={closeModal}>Abbrechen</button>
            <button id='newslide-btn-save' onClick={handleSave}><SaveIcon className='newslide-icon-save'/>Speichern</button>
        </div>
    )
}

const AddNewSlide = ({addSlide, urlImage})=>{
    const [verse, setVerse] = useState('')
    const [fontSize, setFontSize] = useState(2.0)
    const [fontColor, setFontColor] = useState('#000000')

    const handleAddSlide = ()=>{
        
        // checks
        if (!verse){
            toast.warning('Bitte schreiben Sie einen Vers')
            return
        }

        if (!fontSize || !fontColor){
            return
        }

        addSlide(verse, fontColor, fontSize)
        clearNewSlide()

    }

    const clearNewSlide = ()=>{
        setVerse('')
        setFontSize(2.0)
        setFontColor('#000000')
    }

    const handleDecreaseFontSize = ()=>{
        let size = fontSize
        if (size > 1){
            let result = size = size - 0.1
            result = +result.toFixed(1)
            setFontSize(result)
        }
    }
    
    const handleIncreaseFontSize = ()=>{
        let size = fontSize
        if (size < 2.5){
            let result = size = size + 0.1
            result = +result.toFixed(1)
            setFontSize(result)
        }
    }


    return (
        <div id='newslide-new' style={{backgroundImage: `url(${urlImage})`}}>
            <p id='newslide-new-caption'>Neuer Vers/Slide</p>
            <div className='tooltip-newslide'>
                <ToolTip right={true} text={`Jeder gewünschte Satz wird einzeln eingegeben und mit „Hinzufügen“ 
                                in die Bibellese eingefügt. Alle Sätze erscheinen der Reihe nach auf der linken Seite`}/>
            </div>
            <textarea id="newslide-vers" placeholder='Hier ein Vers schreiben...' value={verse}
                        onChange={(e)=>{setVerse(e.target.value)}} 
                        style={{fontSize: `${fontSize * 0.65}rem`, color: fontColor}}>
            </textarea>
            <div id='newslide-font-size'>
                <label htmlFor="font-size-ns">Schriftgröße</label>
                <div>
                    <button onClick={handleDecreaseFontSize}>-</button>
                    <input type="text" id="font-size-ns" value={fontSize} readOnly={true}/>
                    <button onClick={handleIncreaseFontSize}>+</button>
                </div>
            </div>
            <div id='newslide-font-color'>
                <label htmlFor="font-color-ns">Schriftfarbe</label>
                <input type="color" id="font-color-ns" value={fontColor} onChange={(e)=>{setFontColor(e.target.value)}}/>
            </div>
            <button id='newslide-btnnew' onClick={handleAddSlide}><NewIcon/>Hinzufügen</button>
        </div>
    )   
}

const FileUpload = ({updateStateFile, updateStateFileUrl, actualImage, lastImageFile, lastImageUrl, setLastImageFile, setLastImageUrl})=>{
    const ref = useRef(null)

    const handleClick = e => {
        ref.current.click();   
    }
    
    const handleChange = e => {
        if (e.target.files[0]){
            const checkExtension = e.target.files[0].name.slice(-3)
            if (checkExtension !== 'jpg' && checkExtension !== 'png' && checkExtension !== 'bmp'){
                e.target.value = ''
                toast.error("Invalid Picture",{draggable: false})
                return
            }

            if (e.target.files[0].size > 1000000){
                e.target.value = ''
                toast.warning("Max.Size 0,5MB!",{draggable: false})
                return
            }

            // update url and file 
            updateStateFile(e.target.files[0])
            const newUrl = URL.createObjectURL(e.target.files[0]);
            updateStateFileUrl(newUrl)

            // save last blob, from file input
            setLastImageFile(e.target.files[0])
            setLastImageUrl(newUrl)
        }
    }

    const handleUpdateWallpaper = async(pictureId)=>{
        try {
            const response = await fetch(`/wallpapers/${pictureId}.jpg`);
            const blob = await response.blob();
            const file = new File([blob], `${pictureId}.jpg`, { type: 'image/jpg' });
            updateStateFile(file)
            updateStateFileUrl(`/wallpapers/${pictureId}.jpg`)
        } catch(error) {
            toast.error('Fehler: ' + error)
        }
    }

    const handleReuploadWallpaper = ()=>{
        updateStateFile(lastImageFile)
        updateStateFileUrl(lastImageUrl)
    }

    return(
        <>
        <div id='wallpapers'>
            <img className='wallpaper-item' src="/wallpapers/1.jpg" alt="wallpaper 1" 
                    onClick={()=>{handleUpdateWallpaper(1)}} 
                    style={{border: actualImage.name === '1.jpg' ? '2px solid black' : '2px solid transparent'}}/>
            <img className='wallpaper-item' src="/wallpapers/2.jpg" alt="wallpaper 2" 
                    onClick={()=>{handleUpdateWallpaper(2)}}
                    style={{border: actualImage.name === '2.jpg' ? '2px solid black' : '2px solid transparent'}}/>
            <img className='wallpaper-item' src="/wallpapers/3.jpg" alt="wallpaper 3" 
                    onClick={()=>{handleUpdateWallpaper(3)}}
                    style={{border: actualImage.name === '3.jpg' ? '2px solid black' : '2px solid transparent'}}/>
            <img className='wallpaper-item' src="/wallpapers/4.jpg" alt="wallpaper 4" 
                    onClick={()=>{handleUpdateWallpaper(4)}}
                    style={{border: actualImage.name === '4.jpg' ? '2px solid black' : '2px solid transparent'}}/>
            <img className='wallpaper-item' src="/wallpapers/5.jpg" alt="wallpaper 5" 
                    onClick={()=>{handleUpdateWallpaper(5)}}
                    style={{border: actualImage.name === '5.jpg' ? '2px solid black' : '2px solid transparent'}}/>
            
            {lastImageFile && <img className='wallpaper-item' src={lastImageUrl} alt="uploaded_picture" 
                                onClick={()=>{handleReuploadWallpaper()}}
                                style={{border: actualImage.name === lastImageFile.name ? '2px solid black' : '2px solid transparent'}}/>}

            <div id='new-wallpaper' onClick={handleClick}>
                <NewWallpaperIcon className='icon-new-wallpaper'/>
            </div>
        </div>
            
        <input type="file" ref={ref} onChange={handleChange} style={{display: 'none'}} accept="image/*" multiple={false}/>
        </>
    )
}



 
