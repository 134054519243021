import { BrowserRouter, Routes, Route } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import Homepage from './pages/Homepage'
import PostView from './pages/Post'
import Administration from './pages/Administration'
import Login from './pages/Login';
import NotFoundPage from './pages/NotFoundPage';
import ForgotPassword from './pages/ForgotPassword';

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path='/' element={<Homepage/>}/>
                <Route path='/post' element={<PostView/>}/>
                 
                    <Route >
                        <Route exact path='/verwaltung' element={<Administration page={{allPosts: true}}/>}/>
                        <Route path='/verwaltung/neuer-beitrag' element={<Administration page={{newPost: true}}/>}/>
                        <Route path='/verwaltung/posts/:postid' element={<Administration page={{viewPost: true}}/>}/>
                        <Route path='/verwaltung/posts/:postid/edit' element={<Administration page={{editPost: true}}/>}/>
                        <Route path='/verwaltung/posts/:postid/slides/:slideid' element={<Administration page={{viewSlide: true}}/>}/>
                    </Route>
                
                <Route path='/login' element={<Login/>}/>
                <Route path='/login/forgotpassword' element={<ForgotPassword/>}/>
                <Route path='*' element={<NotFoundPage/>}/>
            </Routes>
        </BrowserRouter>
    )
}

export default App;
