import {ReactComponent as DeleteIcon} from '../icons/delete.svg'
import {ReactComponent as CleanIcon} from '../icons/clean.svg'
import {ReactComponent as EditIcon} from '../icons/edit_big.svg'
import {ReactComponent as LinkIcon} from '../icons/link.svg'
import {ReactComponent as DownloadIcon} from '../icons/download.svg'

import { saveAs } from 'file-saver';

import {QRCodeCanvas} from 'qrcode.react'
import { useEffect, useRef } from 'react'
import { useNavigate, useParams } from "react-router-dom"
import '../styles/postModalBoxes.css'

export function SettingsBox({close, refButton, onDelete, onClean, onEdit}){
    const ref = useRef(null)
    const navigate = useNavigate()
    let { postid } = useParams()

    const handleClickOutside = ()=>{
        close()
    }

    useOutsideAlerter(ref, handleClickOutside, refButton)
    return (
        <div id='settings-container' ref={ref}>
            <div className='s-b-edit s-b-icondiv' onClick={()=>{navigate(`/verwaltung/posts/${postid}/edit`)}}>
                <EditIcon className='s-icon'/><p>Ändern</p>
            </div>
            
            <div className='s-b-clean s-b-icondiv' onClick={onClean}>
                <CleanIcon className='s-icon'/><p>Beitrag zurücksetzen</p>
            </div>
            
            <div className='s-b-delete s-b-icondiv' onClick={onDelete}>
                <DeleteIcon className='s-icon'/><p>Beitrag löschen</p>
                </div>
        </div>
    )
}



export function ShareBox({close, refButton, qrCodeValue, onCopyLink}){
    const ref = useRef(null)
    const refQrcode = useRef(null)
    let { postid } = useParams()
    
    const handleClickOutside = ()=>{
        close()
    }
    
    useOutsideAlerter(ref, handleClickOutside, refButton)

    const handleDownload = ()=>{
        const qrcodeCanvas = refQrcode.current.children[0]
        qrcodeCanvas.toBlob((blob)=>{
            saveAs(blob, `Beitrag ${postid} - QRCode.jpg`)
        })
    }

    return (
        <div id='share-container' ref={ref}>
            <div className='qrcode-container-share' ref={refQrcode}>
                <QRCodeCanvas 
                    className='qrcode-share' 
                    value={qrCodeValue}
                    includeMargin={true}
                    size={128}
                    level={"H"}
                    imageSettings={{
                        src: "/favicon.ico",
                        x: undefined,
                        y: undefined,
                        height: 24,
                        width: 24,
                        excavate: true,
                    }}/>
            </div>
            <div className='s-b-edit s-b-icondiv' onClick={handleDownload}>
                <DownloadIcon className='s-icon'/><p>QRCode herunterladen</p>
            </div>

            <div className='s-b-edit s-b-icondiv' onClick={onCopyLink}>
                <LinkIcon className='s-icon'/><p>Beitragslink kopieren</p>
            </div>

        </div>
    )
}

function useOutsideAlerter(ref, onClickOutside, refButton) {
    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target) && !refButton.current.contains(event.target)) {
                onClickOutside()
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref])
  }