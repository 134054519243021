import '../styles/viewSlideInfo.css'

import { useParams, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";

import { query, collection, onSnapshot } from "firebase/firestore";
import { db } from "../db/firebase";


import { getSingleSlide } from "../services/slides";

import {ReactComponent as LikeIcon} from '../icons/like.svg'
import {ReactComponent as CommentIcon} from '../icons/comment.svg'
import {ReactComponent as ViewIcon} from '../icons/view.svg'
import { NavSlides } from './Navigator';
import { LoadingDivModal } from './LoadingDiv';
import { ToastContainer, toast } from 'react-toastify';

export default function ViewSlideInfo(){

    const [likes, setLikes] = useState([])
    const [views, setViews] = useState([])
    const [questions, setQuestions] = useState([])
    const [slide, setSlide] = useState('')
    const [nextSlideId, setNextSlideId] = useState(undefined)
    // const [showEditSlide, setShowEditSlide] = useState(false)
    let { postid, slideid } = useParams()
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(true)
    

    // Get Data
    useEffect(()=>{

        // Slide data, 1x
        (async()=>{
            try {
                setIsLoading(true)
                const res = await getSingleSlide(postid, slideid)
                if (!res.slide){navigate('/verwaltung')}
                setSlide(res.slide)
                setNextSlideId(res.nextSlideId)
                setIsLoading(false)
            } catch (error) {
                toast.error('Fehler: ' + error, {draggable: false})
                setIsLoading(false)            
            }
        })()

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[slideid])


    // Observer's Firebase
    useEffect(()=>{

        // Likes
        const qLikes = query(collection(db, 'posts', postid, 'slides', slideid, 'likes'))         
        const unsubSnapLikes = onSnapshot(qLikes, (snapshot) => {
            let data = [];
            snapshot.forEach((doc) => {
                if (doc.exists()){
                    data.push({ ...doc.data()})
                }
            })
            setLikes(data)
        })

        // Views
        const qViews = query(collection(db, 'posts', postid, 'slides', slideid, 'view'))         
        const unsubSnapViews = onSnapshot(qViews, (snapshot) => {
            let data = [];
            snapshot.forEach((doc) => {
                if (doc.exists()){
                    data.push({ ...doc.data()})
                }
            })
            setViews(data)
        })

        // Questions 
        const qQuestions = query(collection(db, 'posts', postid, 'slides', slideid, 'questions'))         
        const unsubSnapQuestions = onSnapshot(qQuestions, (snapshot) => {
            let data = [];
            snapshot.forEach((doc) => {
                if (doc.exists()){
                    doc.data().questions.forEach((q)=>{
                        data.push({ ...q})
                    })
                    
                }
            })
            setQuestions(data)
        })

        return ()=>{
            unsubSnapLikes()
            unsubSnapViews()
            unsubSnapQuestions()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[slideid])


    const handleGoBack = ()=>{
        navigate(`/verwaltung/posts/${postid}`)
    }

    const handleGoNextSlide = ()=>{
        if (nextSlideId){
            navigate(`/verwaltung/posts/${postid}/slides/${nextSlideId}`)
        }
    }

    // const toggleShowEditSlide = ()=>{
    //     setShowEditSlide(prev => !prev)
    // }

    // const updateSlideTitle = (newVerse)=>{
    //     setSlide({
    //         ...slide,
    //         verse: newVerse
    //     })
    // }


    
    return(
        <>
            {slide && <NavSlides 
                handleGoBack={handleGoBack}
                nextSlideId={nextSlideId} 
                handleNextSlide={handleGoNextSlide}
                previousPage={'Beitrag'} 
                title={slide.verse} />}
            {slide && <div id="structure-slideview-info">
                <div id="container-likes-views">
                    <div id="view-counter-div">
                        <ViewIcon className="button-view-verwaltung"/>
                        <p>{views.length}</p>
                    </div>
                    <div id="likes-counter-div">
                        <LikeIcon className="button-like-verwaltung"/>
                        <p>{likes.length}</p>
                    </div>
                </div>
                <div id="container-questions-list">
                    <CommentIcon className="button-questions-verwaltung"/>
                    <div id="questions-list">
                        {questions[0] && questions.map((item, id)=>{
                            return <p key={id} className="question-bubble">{item.text}</p>})}
                    </div>
                </div>
            </div>}
            
            {isLoading && <LoadingDivModal/>}

            <ToastContainer/>

        </>
    )
}

