import { useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import '../styles/administration.css'

import { auth } from '../db/firebase'
import { useAuthState } from 'react-firebase-hooks/auth'
import { signOut } from "firebase/auth"

import NewPost from "../components/NewPost"
import MyPosts from "../components/Overview"
import ViewPost from "../components/ViewPost"
import ViewSlideInfo from "../components/ViewSlideInfo"
import Navbar from "../components/Navbar"
import Sidebar from "../components/Sidebar"


export default function Administration({page}){

    const [user] = useAuthState(auth)
    const navigate = useNavigate()
    const refSidebar = useRef(null)
    const [showSidebar, setShowSidebar] = useState(false)
    const [caption, setCaption] = useState('')


    useEffect(()=>{
        
        if (!user){navigate('/login')}
        if (user && user.isAnonymous === true){logout()}

        document.title = 'Verwaltung | BibelLesenApp'

        if (page.allPosts){
            setCaption('Meine Beiträge')
        } else if (page.newPost){
            setCaption('Neuer Beitrag')
        } else {
            setCaption('')
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[user, page])

    const logout = async()=>{
        try {
            await signOut(auth)
            navigate('/')
        } catch (error) {
            alert('Error logout: ' + error)
        }
    }

    const toggleSidebar = ()=>{
        setShowSidebar(prev=>!prev)
        refSidebar.current.classList.toggle('active')

    }

    const handleNavigate = (address)=>{
        navigate(address)
    }

    return(
        <>  
            {user && <Navbar user={user} logout={logout} toggleSidebar={toggleSidebar} caption={caption}/>}
            <Sidebar refSidebar={refSidebar} state={showSidebar} handleNavigate={handleNavigate} toggleSidebar={toggleSidebar}/>
            <div id="structure-admin">
                {user && page.allPosts && <MyPosts/>}
                {user && page.newPost && <NewPost/>}
                {user && page.viewPost && <ViewPost/>}
                {user && page.viewSlide && <ViewSlideInfo/>}
                {user && page.editPost && <NewPost/>}
            </div>
        </>
    )
}


