import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth } from 'firebase/auth'

const firebaseConfig = {
  apiKey: "AIzaSyAITYzSFMBjj116q4JYbcfp3TgGlWJveiA",
  authDomain: "scrollb.gemeinschaft-muenchen.de",
  // authDomain: "bibel-tiktok.firebaseapp.com",
  projectId: "bibel-tiktok",
  storageBucket: "bibel-tiktok.appspot.com",
  messagingSenderId: "467506192760",
  appId: "1:467506192760:web:d3953869e4a5798870e88d",
  measurementId: "G-18S31ZCN4X"
}

const app = initializeApp(firebaseConfig);
const db = getFirestore(app)
const storage = getStorage(app)
const auth = getAuth()


export {db, storage, auth }