import { motion, AnimatePresence } from "framer-motion"
import '../styles/instructions.css'

export default function Instructions({isVisible}){
    return (
        <AnimatePresence>
            {isVisible && (
                <motion.div>
                    <div id="container-instructions">
                        <img id="pfeil" src="/pfeil.png" alt="" />
                    </div>
                </motion.div>
            )}
        </AnimatePresence>
    )
}