import '../styles/noPost.css'
import {ReactComponent as NotFoundIcon} from '../icons/notfound.svg'
import {ReactComponent as BlockedIcon} from '../icons/blocked.svg'
import {ReactComponent as RemovedIcon} from '../icons/removed.svg'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'


export default function NoPost({error}){
    const [message, setMessage] = useState('')
    const navigate = useNavigate()

    useEffect(()=>{
        switch (error.message){
            case 'PIN':
                setMessage('Fehler: PIN/ID nicht gefunden')
                break;
            case 'REMOVED':
                setMessage('Fehler: Beitrag wurde gelöscht')
                break;
            case 'INACTIVE':
                setMessage('Beitrag wurde deaktiviert')
                break;
            case 'NOTFOUND':
                setMessage('Beitrag nicht gefunden')
                break;
            default: 
                if (error.code === 'storage/object-not-found'){
                    setMessage('Fehler: Ungültiger Hintergrund ')
                } else {
                    setMessage('Fehler: ' + error)
                }
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    return(
        <div id='nopost-c'>
            {error.message === 'REMOVED' ? <RemovedIcon id='nopost-icon'/> : error.message === 'INACTIVE' ? <BlockedIcon id='nopost-icon'/> : <NotFoundIcon id='nopost-icon'/>}
            <p>{message}</p>
            <button onClick={()=>{navigate('/')}}>Zurück zur Startseite</button>
        </div>

        )
}