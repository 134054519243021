import { sendPasswordResetEmail, signInAnonymously, updateProfile } from "firebase/auth";
import { auth } from '../db/firebase'
import { useAuthState } from 'react-firebase-hooks/auth'
import { onAuthStateChanged, 
        GoogleAuthProvider, 
        signInWithRedirect, 
        createUserWithEmailAndPassword, 
        signInWithEmailAndPassword } from 'firebase/auth'

async function signInAnonym(){
    await signInAnonymously(auth)
}

function useAuth(){
    return useAuthState(auth)
}
 
function authChanged(callback){
    return onAuthStateChanged(auth, callback)
}

function signInWithGoogleRedirect(){
    const provider = new GoogleAuthProvider()
    provider.addScope('https://www.googleapis.com/auth/userinfo.profile')
    provider.setCustomParameters({   
        prompt : "select_account "
    })
    return signInWithRedirect(auth, provider)
}

async function createUserWithEmail(name, email, password){
    
    await createUserWithEmailAndPassword(auth, email, password)
    // const userCredential = await createUserWithEmailAndPassword(auth, email, password)
    // const user = userCredential.user
    await updateProfile(auth.currentUser, {
        displayName: name
    })
}

async function signInWithEmail(email, password){
    await signInWithEmailAndPassword(auth, email, password)
}


async function resetPassword(email){
    return await sendPasswordResetEmail(auth, email) 
}

export {signInAnonym, signInWithGoogleRedirect, useAuth, authChanged, createUserWithEmail, signInWithEmail, resetPassword }