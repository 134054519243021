import '../styles/login.css'
import {GoogleButton} from 'react-google-button'
import { createUserWithEmail, signInWithEmail, signInWithGoogleRedirect, useAuth } from '../services/firebaseAuth';

import { useNavigate } from 'react-router-dom'
import { useEffect, useRef, useState } from 'react';

import {ReactComponent as DoneIcon} from '../icons/done.svg'
import {ReactComponent as BackIcon} from '../icons/back.svg'

import { LoadingDivModal } from '../components/LoadingDiv'
import { ToastContainer, toast } from 'react-toastify';
import { Button } from 'antd';

export default function Login(){
    const navigate = useNavigate();
    const [user] = useAuth() || [{}]
    const [isLoading, setIsLoading] = useState(false)
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [passwordConfirm, setPasswordConfirm] = useState('')
    const [registerForm, setRegisterForm] = useState(false)

    const refPasswordConfirm = useRef(null)
    const refPasswordSize = useRef(null)


    useEffect(()=>{
        const cookieLoading = JSON.parse(localStorage.getItem('isLoadingSignIn'))
        if (cookieLoading?.status){
            setIsLoading(true)
            localStorage.removeItem('isLoadingSignIn')
        }
        if (user){
            if (!user.isAnonymous){
                navigate('/verwaltung')
            }
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[user])

    const handleSignInGoogle = async()=>{
        try {
            // store in localstorage to show Loading button
            localStorage.setItem('isLoadingSignIn', JSON.stringify({status: true}))
            signInWithGoogleRedirect()
        } catch (error) {
            toast.error('Login Fehler: ' + error)
            setIsLoading(false)
        }
    }

    const handleSignInWithEmail = async()=>{

        // Simple validate
        if (!email || !password){
            toast.warning('Bitte E-mail und Kennwort eingeben')
            return
        }

        try {
            setIsLoading(true)
            await signInWithEmail(email, password)
        } catch (error) {
            
            switch (error.code){
                case 'auth/invalid-email':
                    toast.warning('Bitte geben Sie eine gültige E-Mail-Adresse ein')
                    break
                case 'auth/invalid-credential':
                    toast.warning('E-mail oder Kennwort falsch')
                    break
                default:
                    toast.error('Login Fehler: ' + error)
                    break
            }
        } finally {
            setIsLoading(false)
        }
    }

    const handleCreateUser = async()=>{

        // validate
        if (!name || name.trim().length < 3){
            toast.warning('Bitte schreiben Sie einen Namen ein')
            return
        }
        if (!email || !password){
            toast.warning('Bitte E-mail und Kennwort eingeben')
            return
        }

        if (password !== passwordConfirm){
            toast.warning('Passwörter stimmen nicht überein')
            return
        }

        try {
            setIsLoading(true)
            await createUserWithEmail(name, email, password)
        } catch (error) {

            switch (error.code){
                case 'auth/weak-password':
                    toast.warning('Das Passwort muss mindestens 6 Zeichen lang sein')
                    break
                case 'auth/invalid-email':
                    toast.warning('Bitte geben Sie eine gültige E-Mail-Adresse ein')
                    break
                case 'auth/email-already-in-use':
                    toast.warning('Diese E-Mail wurde bereits verwendet')
                    break
                default:
                    toast.error('Login Fehler: ' + error)
                    break
            }
        } finally {
            setIsLoading(false)
        }
    }

    const handlePassword = (e)=>{
        setPassword(e.target.value)
        if(e.target.value.length < 6){
            refPasswordSize.current.style.color = '#c03c3c'
            refPasswordSize.current.style.fill = '#c03c3c'
        } else {
            refPasswordSize.current.style.color = '#3e8e3e'
            refPasswordSize.current.style.fill = '#3e8e3e'
        }
    }

    const handleConfirmPassword = (e)=>{
        setPasswordConfirm(e.target.value)
        if(e.target.value !== password){
            refPasswordConfirm.current.style.color = '#c03c3c'
            refPasswordConfirm.current.style.fill = '#c03c3c'
        } else {
            refPasswordConfirm.current.style.color = '#3e8e3e'
            refPasswordConfirm.current.style.fill = '#3e8e3e'
        }
    }

    const handleEnterSignIn = (e)=>{
        if (e === 'Enter'){
            handleSignInWithEmail()
        }
    }
    
    const handleEnterCreateUser = (e)=>{
        if (e === 'Enter'){
            handleCreateUser()
        }
    }

    return(
        <div id="structure-login" style={{backgroundImage: `url('/wallpaper.jpg')`}}>
            <div id='logo-scrollb-login'>
                <img src="/scrollblogo.png" alt="scrollb-logo" />
            </div>
            
            <div id='container-login'>
                {!registerForm ? 
                    (<>
                        <h3>Anmeldung</h3>
                        <div className='btn-google-login'><GoogleButton onClick={handleSignInGoogle} type={'light'} label='Weiter mit Google'/></div>
                        
                        <div className='divisor'><div></div><p>oder</p><div></div></div>

                        <div id='container-login-userpassword'>
                            <label htmlFor="email-login">E-mail</label>
                            <input type="email" id="email-login" value={email}
                                    placeholder='email@irgendwas.com' onChange={(e)=>{setEmail(e.target.value)}}/>

                            <label htmlFor="password-login">Kennwort</label>
                            <input type="password" id="password-login" placeholder='******'
                                    value={password} onKeyUp={(e)=>{handleEnterSignIn(e.key)}} onChange={(e)=>{setPassword(e.target.value)}}/>

                            <button className='btn-signin' onClick={handleSignInWithEmail}>Anmelden</button>
                            <button className='btn-newuser' onClick={()=>{setRegisterForm(true)}}>Registrieren</button>

                            <Button onClick={()=>{navigate('/login/forgotpassword')}} type='link' className='btn-forgot'>Kennwort vergessen?</Button>
                        </div>
                    </>)
                : 
                    <>  
                        <button onClick={()=>{setRegisterForm(false)}} className='btn-login-goback'><BackIcon className='i-goback'/>Zurück</button>
                        <div id='container-login-newuser'>
                            <h3>Registrieren</h3>
                            <label htmlFor="name-login">Vorname</label>
                            <input type="text" id="name-login" value={name}
                                placeholder='Max Mustermann' onChange={(e)=>{setName(e.target.value)}}/>
                            
                            <label htmlFor="email-login">E-mail</label>
                            <input type="email" id="email-login" value={email}
                                placeholder='email@irgendwas.com' onChange={(e)=>{setEmail(e.target.value)}}/>

                            <label htmlFor="password-login">Kennwort</label>
                            <input type="password" id="password-login" placeholder='Mindestens 6 Zeichen...'
                                value={password} onChange={handlePassword}/>
                            
                            <label htmlFor="confirmpassword-login">Kennwort bestätigen</label>
                            <input type="password" id="confirmpassword-login" placeholder='Kennwort bestätigen...'
                                value={passwordConfirm} onKeyUp={(e)=>{handleEnterCreateUser(e.key)}} onChange={handleConfirmPassword}/>
                            
                            <p ref={refPasswordSize} className='requirements-caption'><DoneIcon/>Mindestens 6 Zeichen...</p>
                            <p ref={refPasswordConfirm} className='requirements-caption'><DoneIcon/>Passwörter stimmen überein</p>
                            
                            <button className='btn-createuser' onClick={handleCreateUser}>Registrieren & Anmelden</button>
                        </div>
                    </>
                }
            </div>

            {isLoading && <LoadingDivModal withNavbar={true} white={true}/>}
            <ToastContainer/> 
            <div></div>
        </div>
    )
}
