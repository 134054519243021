import {
    collection,
    deleteDoc,
    doc, 
    getDoc,
    getDocs,
    setDoc,
    updateDoc
} from 'firebase/firestore'
import {db, storage} from '../db/firebase'
import { addSlidesToPost } from './slides';
import { Post } from '../models/post.model';
import { getDownloadURL, ref } from "firebase/storage"

// add/update post (with slides)
export async function addPost(post, slides){

    // New post doc on posts collection
    await setDoc(doc(db, 'posts', post.id), JSON.parse(JSON.stringify(post)), {merge: true})

    // New Slides collections inside post doc, all slides to slides collection
    await addSlidesToPost(post.id, slides)
  
}

// Get ONLY post data (id)
export async function getPost(postid){

    const docRef = doc(db, 'posts', postid)
    const querySnapshot = await getDoc(docRef)
    
    // removed
    if (querySnapshot.data()?.removed){
        throw new Error('REMOVED')
    }

    if (querySnapshot.exists()){
        const data = querySnapshot.data()
        let p = new Post(data.title, data.backgroundfilename, data.id, data.created_by, data.active)
        return p
    } else {
        throw new Error('NOTFOUND')
    }
}

// Get all post from creator
export async function getAllMyPosts(userid){
    const snapshot = await getDocs(collection(db, 'posts'))
    if (snapshot){
        const data = []
        snapshot.forEach((doc)=>{
            if (doc.data().created_by === userid && !doc.data().removed){
                data.push(doc.data())
            }
        })
        return data
    } else {
        return []
    }
}

// Get all pins from posts
export async function isThePinAvailable(pin){
    if (!pin){return false}
    const snapshot = await getDoc(doc(db, 'posts', pin))
    if (snapshot.exists()){
        return false
    } else {
        return true
    }
}

// clear likes, questions, views from post
export async function resetStatsPost(postid){
    const slidesRef = await getDocs(collection(db, 'posts', postid, 'slides'))

    // each slide, get likes, view, questions, remove all docs
    await Promise.all(slidesRef.docs.map(async(slide)=>{
        let [likesRef, viewRef, questionsRef] = await Promise.all([
            getDocs(collection(db, 'posts', postid, 'slides', slide.id, 'likes')),
            getDocs(collection(db, 'posts', postid, 'slides', slide.id, 'view')),
            getDocs(collection(db, 'posts', postid, 'slides', slide.id, 'questions'))
        ])


        // without waiting
        likesRef.forEach((item)=>{
            deleteDoc(doc(db, 'posts', postid, 'slides', slide.id, 'likes', item.id))
        })
        
        viewRef.forEach((item)=>{
            deleteDoc(doc(db, 'posts', postid, 'slides', slide.id, 'view', item.id))
        })
        
        questionsRef.forEach((item)=>{
            deleteDoc(doc(db, 'posts', postid, 'slides', slide.id, 'questions', item.id))
        })

    }))

}


// make post invisible, delete all docs cost too much
export async function deletePost(postid){
    
    // all sub collections are there

    const docRef = doc(db, 'posts', postid)
    await updateDoc(docRef, {
        removed: true
    })
    
}


export async function setPostStatus(postid, newStatus){
    const docRef = doc(db, 'posts', postid)
    await updateDoc(docRef, {
        active: newStatus
    })
}


export async function getDownloadPictureURL(location){
    return await getDownloadURL(ref(storage, location))
}

export async function getBackgroundReference(location){
    return await ref(storage, location)
}